.customContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-evenly;
  width: 100%;
  overflow: hidden;
  align-items: center;
}

.flexItemLeft > h1,
.flexItemLeft > h2 {
  color: white;
}
.abstractCodesContainer > h1,
.abstractCodesContainer > h2,
.abstractCodesContainer > h3 {
  color: #166088;
  text-align: center;
}
input {
  background-color: #f6f6f6 !important;
  width: fit-content;
}

.cardContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  max-width: 70%;
  height: 60vh;
  /* margin-top: 3rem; */
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.363);
  backdrop-filter: blur(6.9px);
  /* margin-right: 3rem; */
  -webkit-backdrop-filter: blur(6.9px);
}

.flexItemLeft {
  background-color: #166088;
  color: white;
  font-size: 1rem;
  order: 1;
  padding: 2rem;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  text-align: center;
  border-radius: 1rem 0rem 0rem 1rem;
}
.flexItemRight {
  background-color: white;
  color: #166088;
  font-size: 1rem;
  order: 2;
  padding: 2rem;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 0rem 1rem 1rem 0rem;
}

.cta {
  background-color: #166088;
  border: 0px;
  color: white;
  padding: 1rem;
  margin: 2rem 0;
  border-radius: 7px;
}
.cta:hover {
  background-color: #00a6ff;
  border-color: #166088;
}
.cta:focus {
  background-color: #00a6ff;
  border-color: #166088;
  border: 0px;
}
/* .cta:disabled {
  background-color: #d4d4d4;
  border-color: #0c0c0c;
  color: #000000;
  border: 1px solid;
  cursor: not-allowed;
} */

.emailLabel {
  font-size: 1.3rem;
  margin-bottom: 2rem;
  text-align: center;
}

.buttonContainer {
  display: grid;
  place-items: center;
}

.debug {
  background-color: red;
}

.abstractCodesContainer {
  display: flex;
  flex-direction: column;
  height: fit-content;
  width: 100%;
  color: #166088;
  background-color: #f7f8fc;
  padding: 2rem;
  border-radius: 1rem;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(6.9px);
  -webkit-backdrop-filter: blur(6.9px);
  border: 1px solid rgba(255, 255, 255, 0.4);

  overflow-y: scroll;
}

.abstractCodesContainer::-webkit-scrollbar {
  width: 6px;
}

.abstractCodesContainer::-webkit-scrollbar-track {
  /* background-color: #f7f8fc; */
  background-color: #f7f8fc00;

  border-radius: 100px;
}

.abstractCodesContainer::-webkit-scrollbar-thumb {
  border-radius: 100px;
  background-color: #166087;
}
.abstractItemContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: #166088;
  background-color: #ffffff;
  /* border-bottom: 1px solid #166088; */
  padding: 0.5rem 1rem;
  margin: 0 0 2rem 0;
  border-radius: 10px;
  box-shadow: 0px 9px 18px 0 rgba(62, 75, 234, 0.04);
  text-align: center;
}
/* .abstractItemContainer:nth-last-child(2) {
  border-radius: 0 0 1rem 1rem;
} */

.abstractPayContainer,
.abstractTitleContainer,
.abstractIdContainer {
  text-align: center;
}

.abstractPayContainer {
  padding: 0 2rem;
}

.ctaButtonContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 2rem;
}
.paid {
  /* background-color: rgb(255, 0, 0); */
  height: 100%;
  padding: 0.75rem 1.5rem;
  border-radius: 2rem;
}

/* CSS */
.buyButton {
  align-items: center;
  appearance: none;
  background-image: radial-gradient(100% 100% at 100% 0, #668697 0, #166087 100%);
  border: 0;
  border-radius: 6px;
  box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, rgba(58, 65, 111, 0.5) 0 -3px 0 inset;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  font-family: 'JetBrains Mono', monospace;
  height: 48px;
  justify-content: center;
  line-height: 1;
  list-style: none;
  overflow: hidden;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
  text-align: left;
  text-decoration: none;
  transition: box-shadow 0.15s, transform 0.15s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  will-change: box-shadow, transform;
  font-size: 1rem;
}

.buyButton:focus {
  box-shadow: #bce03c 0 0 0 1.5px inset, rgba(45, 35, 66, 0.4) 0 2px 4px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset;
}

.buyButton:hover {
  box-shadow: rgba(45, 35, 66, 0.4) 0 4px 8px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #8b8a8a 0 -3px 0 inset;
  transform: translateY(-2px);
}

.buyButton:active {
  box-shadow: #00fa36 0 3px 7px inset;
  transform: translateY(2px);
}

/* Extra small devices (phones, 600px and down) */
/* @media only screen and (max-width: 1500px) {
  .cardContainer {
    flex-direction: column;
  }
  .flexItemRight,
  .flexItemLeft {
    width: 95vw;
    padding: 3rem;
  }
  .flexItemLeft {
    border-radius: 1rem 1rem 0rem 0rem;
  }
  .flexItemRight {
    border-radius: 0rem 0rem 1rem 1rem;
  }
} */

@media only screen and (max-width: 1500px) {
  .cardContainer {
    font-size: 10px;
    flex-direction: column;
  }

  .flexItemLeft {
    border-radius: 1rem 1rem 0rem 0rem;
  }
  .flexItemRight {
    border-radius: 0rem 0rem 1rem 1rem;
  }
}

@media only screen and (max-width: 1100px) {
  .cardContainer {
    font-size: 8px;
    align-items: center;
    max-width: 70%;
  }
  .emailLabel {
    font-size: 0.9rem;
  }
  p {
    font-size: 0.9rem;
  }
}
