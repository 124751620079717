.loginContainer {
  height: 80vh;
  width: 50%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 2rem 0;
  overflow-y: visible;
  border-radius: 10px;
}

.loginContainer > * {
  overflow: hidden;
}
.loginContainer::-webkit-scrollbar {
  display: none;
}

.loginContainer::-webkit-scrollbar-track {
  display: none;
}

.loginContainer::-webkit-scrollbar-thumb {
  display: none;
}

.emailLabel {
  width: 49px;
  height: 15px;
  margin: 34px 31px 18px 0;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.89;
  letter-spacing: normal;
  text-align: left;
  color: #adafb2;
}
.emailPLaceholder {
  width: 356px;
  height: 42px;
  margin: 18px 0 35px;
  padding: 15px 149px 10px 16px;
  border-radius: 7px;
  background-color: #edeff7;
}
.passwordLabel {
  width: 85px;
  height: 14px;
  margin: 35px 271px 18px 0;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.89;
  letter-spacing: normal;
  text-align: left;
  color: #adafb2;
}

.privacyPolicy {
  width: 297px;
  height: 24px;
  margin: 28px 53px 44px 6px;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  text-align: left;
  color: #c3c4c6;
  overflow-wrap: break-word;
}

.loginCta {
  width: 190px;
  height: 42px;
  margin-left: auto;
  margin-right: auto;
  padding: 12px 70px 12px 71px;
  border-radius: 7px;
  background-color: #3d1beb;
}

.loginCta:hover {
  background-color: #3d1beb;
}
.loginCta:disabled {
  background-color: #3e1beb4d;
  cursor: not-allowed !important;
}
.loginCta:active {
  background-color: #3d1beb;
}

.loginCta:focus {
  background-color: #3d1beb;
}

@media only screen and (max-width: 1000px) {
  .loginContainer {
    height: 100%;
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    background-color: white;
    padding: 5rem 0;
  }
  .emailLabel {
    width: 49px;
    height: 15px;
    margin: 34px 31px 18px 0;
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.89;
    letter-spacing: normal;
    text-align: left;
    color: #adafb2;
  }
  .emailPLaceholder {
    width: 356px;
    height: 42px;
    margin: 18px 0 35px;
    padding: 15px 149px 10px 16px;
    border-radius: 7px;
    background-color: #edeff7;
  }
  .passwordLabel {
    width: 85px;
    height: 14px;
    margin: 35px 271px 18px 0;
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.89;
    letter-spacing: normal;
    text-align: left;
    color: #adafb2;
  }

  .privacyPolicy {
    width: 297px;
    height: 24px;
    margin: 28px 53px 44px 6px;
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: normal;
    text-align: left;
    color: #c3c4c6;
    overflow-wrap: break-word;
  }

  .loginCta {
    width: 190px;
    height: 42px;
    margin-left: auto;
    margin-right: auto;
    padding: 12px 70px 12px 71px;
    border-radius: 7px;
    background-color: #3d1beb;
  }
}
