.customContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-evenly;
  width: 100%;
  overflow: hidden;
}

.paymentsListContainer > h1,
.paymentsListContainer > h2,
.paymentsListContainer > h3 {
  color: #166088;
  text-align: center;
}

.debug {
  background-color: red;
}

.paymentsListContainer {
  display: flex;
  flex-direction: column;
  height: fit-content;
  width: 100%;
  color: #166088;
  background-color: #f7f8fc;
  padding: 2rem;
  border-radius: 1rem;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(6.9px);
  -webkit-backdrop-filter: blur(6.9px);
  border: 1px solid rgba(255, 255, 255, 0.4);

  overflow-y: scroll;
}

.paymentsListContainer::-webkit-scrollbar {
  width: 6px;
}

.paymentsListContainer::-webkit-scrollbar-track {
  /* background-color: #f7f8fc; */
  background-color: #f7f8fc00;

  border-radius: 100px;
}

.paymentsListContainer::-webkit-scrollbar-thumb {
  border-radius: 100px;
  background-color: #166087;
}

.paymentsListUIDContainer {
  width: 20%;
}
.paymentsListAuthorEmailContainer,
.paymentsListPaidByContainer {
  /* flex: 1 1 0px; */
  width: 37.5%;
}

.paymentsListPriceContainer {
  width: 5%;
}

.paymentsListButtonContainer {
  text-align: end;
  padding-top: 0.5rem;
}

.paymentsListItemContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: #166088;
  background-color: #ffffff;
  /* border-bottom: 1px solid #166088; */
  padding: 0.5rem 1rem;
  margin: 0 0 2rem 0;
  border-radius: 10px;
  box-shadow: 0px 9px 18px 0 rgba(62, 75, 234, 0.04);
}
