.card {
  /* From https://css.glass */
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.61);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(6.9px);
  -webkit-backdrop-filter: blur(6.9px);
  border: 1px solid rgba(255, 255, 255, 0.4);
  padding: 1rem;
  margin: 2rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.textWhite {
  color: white;
}

@media screen and (max-width: 480px) {
}

@media screen and (min-width: 481px) and (max-width: 1000px) {
  h2 {
    font-size: 1.1rem;
  }

  .card {
    width: 90%;
  }
}
