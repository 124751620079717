.trafficContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  height: 90vh;
}

.trafficIcon {
  font-size: 2rem !important;
}

.flexItemLeft {
  display: flex;
  flex-direction: column;
  order: 1;
  flex-grow: 1;
  /*justify-content: space-between;*/
  justify-content: center;
  /* width: 30vw; */
  /* height: 90vh; */
  height: 100%;
  /*padding-right: 50px;*/
}
.flexItemLeft1 {
  order: 1;
}
.flexItemLeft2 {
  order: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.analyticsSection {
  border-radius: 1rem;
  padding: 1rem;
  margin: 1rem;
  font-size: 1.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
}
.facebookSection {
  background-color: #1778f2;
  color: white;
}
.twitterSection {
  background-color: #1da1f2;
  color: white;
}
.linkedInSection {
  background-color: #0078b5;
  color: white;
}
.flexItemRight {
  display: flex;
  order: 2;
  flex-grow: 4;
  overflow: hidden;
  justify-content: center;
  align-content: center;
  height: 100%;
  align-items: center;
  border-radius: 1.5rem;
  margin-left: 20px;
}
.websitesTableHead,
.websitesTableBody {
  display: block;
}
.websitesTableBody {
  height: 60vh; /* Just for the demo          */
  /* width: 40vw; */
  overflow-y: auto; /* Trigger vertical scroll    */
  overflow-x: hidden; /* Hide the horizontal scroll */
}
@media screen and (max-width: 1000px) {
  /* .container {
    flex-wrap: wrap;
    flex-basis: auto;
  } */
  .flexItemRight {
    flex-shrink: 3;
    border-radius: 1.5rem;
  }
  .flexItemLeft {
    flex-shrink: 3;
  }
}

@media screen and (max-width: 480px) {
  .trafficContainer {
    flex-wrap: nowrap;
    flex-direction: column;
  }

  .flexItemRight {
    flex-shrink: 3;
    display: flex;
    order: 2;
    width: 100%;
    height: auto;
    justify-content: none;
    align-content: center;
    overflow: hidden;
    height: fit-content;
    border-radius: 1.5rem;
  }

  .mobileMessage {
    order: 2;
  }

  .flexItemLeft1 {
    flex-shrink: 2;
  }

  .analyticsSection {
    font-size: 1.3rem;
    width: 75%;
  }

  h2 {
    font-size: 14px;
  }

  .flexItemLeft {
    justify-content: normal;
    width: 100%;
    height: auto;
    align-items: center;
    order: 1;
  }

  .trafficIcon {
    font-size: 1rem !important;
  }

  div {
    font-size: 1rem;
  }
}

@media screen and (min-width: 481px) and (max-width: 1000px) {
  .trafficContainer {
    flex-wrap: nowrap;
    flex-direction: row;
  }

  .flexItemLeft {
    justify-content: normal;
    height: auto;
    align-items: center;
    order: 1;
  }

  .flexItemRight {
    display: flex;
    order: 2;
    width: 30vw;
    justify-content: none;
    align-content: center;
    height: fit-content;
    border-radius: 1.5rem;
    /*min-width: 50vw;*/
  }

  .analyticsSection {
    font-size: 1.3rem;
    width: 75%;
  }

  h2 {
    font-size: 14px;
  }

  .trafficIcon {
    font-size: 1.5rem !important;
  }
}

@media only screen and (max-width: 1600px) and (min-width: 1100px) {
  /* .container {
    flex-wrap: wrap;
    flex-basis: auto;
  } */

  .flexItemRight {
    height: 100vh;
  }
  .flexItemLeft {
    width: 25vw;
    height: 80vh;
  }

  .analyticsSection {
    font-size: 1.4rem;
    width: 60%;
  }
}
