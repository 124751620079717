/* .out {
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
} */

/* .titleH1,
.titleH2 {
  color: white;
} */

.flexItemLeft > h1,
.flexItemLeft > h2 {
  color: white;
}
input {
  background-color: #f6f6f6 !important;
  width: fit-content;
}

input {
  background-color: #f6f6f6 !important;
  width: fit-content;
}

.cardContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  max-width: 70%;
  height: 60vh;
  /* margin-top: 3rem; */
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.363);
  backdrop-filter: blur(6.9px);
  /* margin-right: 3rem; */
  -webkit-backdrop-filter: blur(6.9px);
}

.flexItemLeft {
  background-color: #166088;
  color: white;
  font-size: 1rem;
  order: 1;
  padding: 2rem;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  text-align: center;
  border-radius: 1rem 0rem 0rem 1rem;
}
.flexItemRight {
  background-color: white;
  color: #166088;
  font-size: 1rem;
  order: 2;
  padding: 2rem;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 0rem 1rem 1rem 0rem;
}

.cta {
  background-color: #166088;
  border: 0px;
  color: white;
  padding: 1rem;
  margin: 2rem 0;
}
.cta:hover {
  background-color: #00a6ff;
  border-color: #166088;
}
.cta:focus {
  background-color: #00a6ff;
  border-color: #166088;
  border: 0px;
}
/* .cta:disabled {
  background-color: #d4d4d4;
  border-color: #0c0c0c;
  color: #000000;
  border: 1px solid;
  cursor: not-allowed;
} */

.emailLabel {
  font-size: 1.3rem;
  margin-bottom: 2rem;
}

.buttonContainer {
  display: grid;
  place-items: center;
}

.debug {
  background-color: red;
}

@media only screen and (max-width: 1500px) {
  .cardContainer {
    font-size: 10px;
    flex-direction: column;
  }

  .flexItemLeft {
    border-radius: 1rem 1rem 0rem 0rem;
  }
  .flexItemRight {
    border-radius: 0rem 0rem 1rem 1rem;
  }
}

@media only screen and (max-width: 1100px) {
  .cardContainer {
    font-size: 8px;
    align-items: center;
    max-width: 70%;
  }
  .emailLabel {
    font-size: 0.9rem;
  }
  p {
    font-size: 0.9rem;
  }
}
