/* .out {
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
} */
.flexItemLeft > h1,
.flexItemLeft > h2,
.flexItemLeftSuccess > h1,
.flexItemLeftSuccess > h2,
.flexItemLeftFail > h1,
.flexItemLeftFail > h2 {
  color: white;
}

input {
  background-color: #f6f6f6 !important;
  width: fit-content;
}

.cardContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  /* width: 60vw; */
  max-width: 70%;
  height: 60vh;
  margin-top: 3rem;
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.363);
  backdrop-filter: blur(6.9px);
  -webkit-backdrop-filter: blur(6.9px);
}

.flexItemLeftSuccess {
  background-color: #168842;
  color: white;
  font-size: 1rem;
  order: 1;
  padding: 2rem;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  text-align: center;
  border-radius: 1rem 0rem 0rem 1rem;
}
.flexItemRightSuccess {
  background-color: white;
  color: #168842;
  font-size: 1.2rem;
  order: 2;
  padding: 2rem;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  border-radius: 0rem 1rem 1rem 0rem;
}
.flexItemLeftFail {
  background-color: #881616;
  color: white;
  font-size: 1rem;
  order: 1;
  padding: 2rem;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  text-align: center;
  border-radius: 1rem 0rem 0rem 1rem;
}
.flexItemRightFail {
  background-color: white;
  color: #881616;
  font-size: 1.2rem;
  order: 2;
  padding: 2rem;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  text-align: center;

  border-radius: 0rem 1rem 1rem 0rem;
}

.cta {
  background-color: #166088;
  border: 0px;
  color: white;
  padding: 1rem;
  margin: 2rem 0;
}
.cta:hover {
  background-color: #00a6ff;
  border-color: #166088;
}
.cta:focus {
  background-color: #00a6ff;
  border-color: #166088;
  border: 0px;
}

.emailLabel {
  font-size: 1.3rem;
  margin-bottom: 2rem;
}

.buttonContainer {
  display: grid;
  place-items: center;
}

.goBackSuccess {
  color: #168842;
  background-color: #ffffff;
  border-color: #168842;
  border-bottom: 2px solid;
  font-size: 1rem;
  margin: 1rem 0 0 0;
}
.goBackSuccess:hover {
  background-color: #168842;
  color: #ffffff;
  border-color: #168842;
  border-bottom: 1px solid;
  margin: 1rem 0 0 0;
}
.goBackFail {
  color: #881616;
  background-color: #ffffff;
  border-color: #881616;
  border-bottom: 2px solid;
  font-size: 1rem;
  margin: 1rem 0 0 0;
}
.goBackFail:hover {
  background-color: #881616;
  color: #ffffff;
  border-color: #881616;
  border-bottom: 1px solid;
  margin: 1rem 0 0 0;
}
.debug {
  background-color: red;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .cardContainer {
    flex-direction: column;
  }
  .flexItemRightSuccess,
  .flexItemRightFail,
  .flexItemLeftSuccess,
  .flexItemLeftFail {
    width: 95vw;
  }
  .flexItemLeftSuccess,
  .flexItemLeftFail {
    border-radius: 1rem 1rem 0rem 0rem;
  }
  .flexItemRightSuccess,
  .flexItemRightFail {
    border-radius: 0rem 0rem 1rem 1rem;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .example {
    background: green;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .example {
    background: blue;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .example {
    background: orange;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .example {
    background: pink;
  }
}
